const VTS = (window.vts = window.vts || {});

jQuery(function () {
  const toggleSubmit = function (isChecked) {
    const submitButton = $("#submit-button");

    if (isChecked) {
      submitButton.removeClass("is-disabled");
    } else {
      submitButton.addClass("is-disabled");
    }

    submitButton.attr("disabled", !isChecked);
  };

  $("#terms-agreement-checkbox").on("click", function () {
    const checked = $(this).is(":checked");
    toggleSubmit(checked);
  });

  $("#terms-agreement-checkbox").attr("disabled", false);
});
