import escapeRegExp from "lodash/escapeRegExp";
import findIndex from "lodash/findIndex";
import identity from "lodash/identity";
import debounce from "lodash/debounce";

const VTS = (window.vts = window.vts || {});

jQuery(function () {
  let serviceProviderName;

  const emailDomainRegExp = function (domain) {
    return new RegExp("s*@" + escapeRegExp(domain) + "\\s*", "i");
  };

  const emailRegExp = function (email) {
    return new RegExp("s*" + escapeRegExp(email) + "s*", "i");
  };

  const emailIsValid = function (emailAddress) {
    return emailAddress.match(/^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i) != null;
  };

  const userHasSSO = function (emailAddress) {
    if (emailAddress && emailIsValid(emailAddress)) {
      return $.get(
        "/authc/sso/enabled?" +
          $.param({
            email_address: emailAddress,
          })
      ).then(function (data) {
        return data["is_sso_enabled"];
      });
    } else {
      return false;
    }
  };

  const tweakForm = function (method) {
    signInMethods.forEach(function (signInMethod) {
      if (signInMethod.key !== method.key) {
        signInMethod.signInElement.addClass("hidden");
        signInMethod.signInElement.prop("disabled", true);
      }
    });

    method.signInElement.prop("disabled", false);
    return method.signInElement.removeClass("hidden");
  };

  const ssoMethod = {
    key: "sso",
    signInElement: $("[data-auth-method=sso]"),

    applies: function (emailAddress) {
      if (this.isVTSException(emailAddress)) {
        return false;
      }

      return userHasSSO(emailAddress);
    },

    signIn: function (event) {
      if (event.code === "Enter") {
        this.signInElement.submit();
      }

      return tweakForm(this);
    },

    vtsExceptions: [
      "sales@viewthespace.com",
      "sales@vts.com",
      "beta@vts.com",
      "super-beta@vts.com",
      "big@viewthespace.com",
      "availabilitiesonly@vts.com",
      "availabilitiesandexisting@vts.com",
    ].map(emailRegExp),

    isVTSException: function (emailAddress) {
      return this.vtsExceptions.some(function (exception) {
        return exception.test(emailAddress);
      });
    },
  };

  const passwordMethod = {
    key: "password",
    signInElement: $("[data-auth-method=password]"),

    applies: function () {
      return true;
    },

    signIn: function () {
      return tweakForm(this);
    },
  };

  const signInMethods = [ssoMethod, passwordMethod];

  const signInMethodListener = function (event) {
    let emailAddress = this.value;

    return $.when(
      ...signInMethods.map(function (method) {
        return method.applies(emailAddress);
      })
    ).then(function (...methodsApplies) {
      return signInMethods[findIndex(methodsApplies, identity)].signIn(event);
    });
  };

  $("form[data-form-type=sign_in]")
    .bind("ajax:success", function (evt, data) {
      if (data.disabled) {
        return VTS.setNotificationMessage({
          message: "Your account has been disabled. Please contact support@vts.com for assistance",
          selector: ".flash-message",
          type: "error",
        });
      } else if (data.unconfirmed) {
        return VTS.setNotificationMessage({
          message: "You have to confirm your account before continuing.",
          type: "error",
          selector: ".flash-message",
        });
      } else if (data.sso === true) {
        VTS.setNotificationMessage({
          message: "Redirecting you to Single Sign On.",
          selector: ".flash-message",
          type: "success",
        });

        return (window.location = data.location);
      } else if (data.saml) {
        VTS.setNotificationMessage({
          message: "Redirecting you back to service provider",
          type: "success",
          selector: ".flash-message",
        });

        return (window.location = data.location);
      } else if (data.location != null) {
        return (window.location = data.location);
      }
    })
    .bind("ajax:error", function (evt, data) {
      return VTS.setNotificationMessage({
        message: data.responseJSON.error,
        selector: ".flash-message",
        type: "error",
      });
    })
    .bind("ajax:before", function () {
      return VTS.removeNotificationMessage({
        selector: ".flash-message",
      });
    });

  if (/[?&]unauthorized=t/.test(location.href)) {
    VTS.setNotificationMessage({
      message: "Please login to continue.",
      type: "error",
      selector: ".flash-message",
    });
  }

  if (/[?&]unlocked=t/.test(location.href)) {
    VTS.setNotificationMessage({
      message: "Your account was successfully unlocked.",
      type: "success",
      selector: ".flash-message",
    });
  }

  if (/[?&]timeout=t/.test(location.href)) {
    VTS.setNotificationMessage({
      message: "Your session expired, please sign in again to continue.",
      type: "error",
      selector: ".flash-message",
    });
  }

  if (/[?&]disabled=t/.test(location.href)) {
    VTS.setNotificationMessage({
      message: "Your account has been disabled. Please contact support@vts.com for assistance.",
      type: "error",
      selector: ".flash-message",
    });
  }

  if (/[?&]confirmation=t/.test(location.href)) {
    VTS.setNotificationMessage({
      message: "Confirmation token invalid.",
      type: "error",
      selector: ".flash-message",
    });
  }

  if (/[?&]SAMLRequest=/.test(location.href)) {
    if (
      (serviceProviderName = $("[data-saml-service-provider-name]").data(
        "saml-service-provider-name"
      ))
    ) {
      VTS.setNotificationMessage({
        message: "Please sign in to continue with " + serviceProviderName,
        type: "success",
        selector: ".flash-message",
      });
    }
  }

  const marketingLinks = {
    ".marketing-top":
      "https://view.ceros.com/vts/vts-office-demand-index-october-2024/p/1?utm_medium=web&utm_source=vts-login",
    ".marketing-left":
      "https://www.vts.com/vts-activate-office?utm_medium=web&utm_source=vts-login",
    ".marketing-right":
      "https://www.vts.com/product/new-releases?utm_medium=web&utm_source=vts-login",
  };

  Object.entries(marketingLinks).forEach(([container, link]) => {
    $(container).click(() => {
      const hasLink = $(container).find("a").length;

      if (!hasLink) {
        window.open(link, "_blank");
      }
    });
  });

  var topWrapper = jQuery(".signIn-page");
  jQuery(".continue-to-web a").on("click", function () {
    topWrapper.addClass("mobile-continue");
  });

  $("#user_email").focus();
  return $("#user_email").on("keyup change", debounce(signInMethodListener, 150));
});
