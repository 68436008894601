const VTS = (window.vts = window.vts || {});

VTS.setNotificationMessage = function (opts) {
  if (opts.message) {
    $(opts.selector).text(opts.message);
  }

  if (opts.append) {
    $(opts.selector).append(opts.append);
  }

  if (opts.content) {
    $(opts.selector).html(opts.content);
  }

  if (opts.type) {
    $(opts.selector).addClass(opts.type);
  }

  return $(opts.selector).show();
};

VTS.removeNotificationMessage = function (opts) {
  VTS.setNotificationMessage({
    selector: opts.selector,
    message: "",
    content: " ",
  });

  return $(opts.selector).hide();
};

jQuery(function () {
  $("form[data-form-type=remote]")
    .bind("ajax:success", function (evt, data) {
      if (data.location) {
        return (window.location = data.location);
      } else {
        return VTS.setNotificationMessage({
          message: data.message,
          selector: ".flash-message",
          type: "success",
        });
      }
    })
    .bind("ajax:error", function (evt, data) {
      return VTS.setNotificationMessage({
        message: data.responseJSON.error_messages[0],
        selector: ".flash-message",
        type: "error",
      });
    })
    .bind("ajax:before", function () {
      return VTS.removeNotificationMessage({
        selector: ".flash-message",
      });
    });
});
